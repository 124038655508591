import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import ProjectContent from '../../components/Project'

export default function Project({ location, data }) {
  const { strapiProject: project } = data
  const {
    meta_description: metaDescription,
    meta_keywords: metaKeywords,
    background_color: backgroundColor,
  } = project

  const meta = []

  if (metaKeywords) {
    meta.push({ name: 'keywords', content: metaKeywords })
  }

  const style = backgroundColor
    ? { backgroundColor, paddingLeft: 'var(--spacing)' }
    : {}

  return (
    <Layout location={location} project={project}>
      <SEO
        title={data.strapiProject.name}
        description={metaDescription}
        meta={meta}
      />
      <Layout.Content style={style}>
        <ProjectContent project={project} />
      </Layout.Content>
    </Layout>
  )
}

Project.propTypes = {
  data: PropTypes.shape({
    strapiProject: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export const projectFragment = graphql`
  fragment UncompressedImage on ImageSharp {
    original {
      src
      width
      height
    }
  }

  fragment VerticalStackImages on VerticalStack {
    top_url
    top {
      extension
      base
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_noBase64
        }
        ...UncompressedImage
      }
    }
    top_vimeo {
      vimeo_url
      width
      height
    }
    bottom_url
    bottom {
      extension
      base
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_noBase64
        }
        ...UncompressedImage
      }
    }
    bottom_vimeo {
      vimeo_url
      width
      height
    }
  }

  fragment Project on StrapiProject {
    download_url
    name
    slug
    background_color
    description
    meta_description
    meta_keywords
    thumbnail {
      childImageSharp {
        fluid(maxWidth: 110) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    fields {
      creditsHTML
      fontsHTML
      projectInformationHTML
    }
    content {
      id
      vimeo_url
      width
      height
      image_url
      left_url
      right_url
      embed_code
      image {
        extension
        base
        childImageSharp {
          fluid(maxWidth: 2400) {
            ...GatsbyImageSharpFluid_noBase64
          }
          ...UncompressedImage
        }
      }
      left {
        extension
        base
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
          ...UncompressedImage
        }
      }
      left_vertical_stack {
        ...VerticalStackImages
      }
      right_vertical_stack {
        ...VerticalStackImages
      }
      left_vimeo {
        vimeo_url
        width
        height
      }
      right_vimeo {
        vimeo_url
        width
        height
      }
      right {
        extension
        base
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
          ...UncompressedImage
        }
      }
    }
  }
`

export const query = graphql`
  query($slug: String!) {
    strapiProject(slug: { eq: $slug }) {
      ...Project
    }
  }
`
